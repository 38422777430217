<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-data-table
          :headers="displayedHeader"
          :items="historyAction"
          item-key="documentId"
          hide-default-footer
          :loading="isLoading"
          loading-text="Sedang memuat..."
          :items-per-page="100"
        >
          <template v-slot:[`item.no`]="{index}">
            {{index + 1}}
          </template>
          <template v-slot:[`item.actionDate`]="{item}">
            <span>{{ dateFormat(item.actionDate) }}</span>
            <p class="grey--text text--darken-2">{{ timeFormat(item.actionDate) }}</p>
          </template>
          <template v-slot:[`item.action`]="{item, index}">
            <span :class="getStyle(index)">{{formatAction(item.action)}}</span>
          </template>
          <template v-slot:[`item.note`]="{item}">
            <pre><p class="ma-0">{{item.note ? item.note : '-'}}</p></pre>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { statusActionWorkflow, dateFormat, timeFormat } from '../../helper/commonHelpers';

export default {
  mounted() {
    this.fetchHistoryApproval();
  },
  data() {
    return {
      isLoading: false,
      headers: [
        {
          text: this.$_strings.companyDocuments.NO_TABLE_HEADER_LABEL,
          value: 'no',
        },
        {
          text: this.$_strings.common.DATE,
          value: 'actionDate',
        },
        {
          text: this.$_strings.common.NAME,
          value: 'stepName',
        },
        {
          text: this.$_strings.companyDocuments.ACTION_TABLE_HEADER_LABEL,
          value: 'action',
        },
        {
          text: this.$_strings.common.NOTE,
          value: 'notes',
        },
      ],
      historyAction: [],
    };
  },
  methods: {
    dateFormat,
    timeFormat,
    getStyle(index) {
      if (index % 2 === 0) {
        return 'blue--text text--lighten-1';
      }
      return 'orange--text text--lighten-1';
    },
    formatAction(action) {
      return statusActionWorkflow(action);
    },
    fetchHistoryApproval() {
      this.isLoading = true;
      this.$_services.approval.getListHistoryApproval(this.$route.params.id)
        .then((res) => {
          this.historyAction = res.data.contents;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  computed: {
    displayedHeader() {
      return this.headers.map((i) => ({
        ...i,
        class: 'white--text primary text-capitalize',
        // cellClass: 'clickable',
      }));
    },
  },
};

</script>
