<template>
  <v-container fluid>
    <v-data-table
      :headers="displayedHeader"
      :items="form.documentSupports"
      item-key="documentInformation.id"
      hide-default-footer
      :items-per-page="100"
    >
      <template v-slot:[`item.no`]="{index}">
        <span>
          {{index + 1}}
        </span>
      </template>
      <template v-slot:[`item.documentName`]="{item, index}">
        <a
          v-if="item.url || item.encodedFile"
          @click="openDocument(item, index)"
        >
          {{item.documentInformation.descriptions}}
          <viewer-image
            :ref="'viewer_image'+index"
            :key="item.url || item.encodedFile"
            :src="item.url || item.encodedFile"
            class="d-none"
          />
        </a>
        <span v-else>
          {{item.documentInformation.descriptions}}
        </span>
      </template>
      <template v-slot:[`item.documentInformation.isMandatory`]="{item}">
        <span v-if="item.documentInformation.isMandatory" class="font-weight-bold">
          {{$_strings.common.YES}}
        </span>
        <span v-else>
          {{$_strings.common.NO}}
        </span>
      </template>
      <template v-slot:[`item.documentInformation.descriptions`]="{item}">
        <span v-if="item.url || item.encodedFile">
          1 dari 1 File
        </span>
        <span v-else>
          0 dari 1 File
        </span>
      </template>
      <template v-slot:[`item.actionColumn`]="{item, index}">
        <div>
          <v-btn
            v-if="item.url || item.encodedFile"
            color="primary"
            icon
            @click="() => deleteFile(index, item)"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
          <div v-else>
            <label
              for="file-upload"
              class="custom-file-upload"
            >
              <v-btn
                :for="'file-upload'+item.documentInformation.id"
                color="primary"
                icon
                :loading="item.loading ? true : false"
                @click="$refs['file-upload'+item.documentInformation.id].click()"
              >
                <v-icon>mdi-upload</v-icon>
              </v-btn>
            </label>
            <input
              :id="'file-upload'+item.documentInformation.id"
              :ref="'file-upload'+item.documentInformation.id"
              type="file"
              accept=".pdf,.jpeg,.jpg,.png"
              @change="e => readFile(e, item)"
              style="display: none;"
            />
            <br>
            <span v-if="item.encodedFile" class="caption">
              File terpilih
            </span>
          </div>
        </div>
      </template>
    </v-data-table>
    <v-row no-gutters v-if="!readOnly">
      <v-col>
        <div class="d-flex align-center">
          <v-checkbox v-model="isAgree"></v-checkbox>
          <div class="body-2">
            {{$_strings.welcomePage.ISAGREE}}
            <template>
              <a
                @click="dialogTermsConditions = true"
              >
                {{$_strings.welcomePage.TERMS}}
              </a>
            </template>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row v-if="!readOnly">
      <v-spacer></v-spacer>
      <v-col cols="auto">
        <v-btn
          :disabled="!(isAgree)"
          @click="submit({isDraft: false})"
          color="primary"
        >
          {{$_strings.common.SAVE}}
        </v-btn>
      </v-col>
      <v-col v-if="!$route.params.id" cols="auto">
        <v-btn
          :disabled="!(isAgree)"
          color="success"
          @click="submit({isDraft: true})"
        >
          {{$_strings.common.SAVE_AS_DRAFT}}
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="mt-2">
      <v-col class="grey--text text--darken-1 caption">
        <span>Legend:</span>
        <p class="ma-0">Dokumen format jpeg, pdf, png, .zip</p>
        <p class="ma-0">Size maksimal 1 MB</p>
        <p v-if="$route.params.defWorkflowId === 15"  class="ma-0">Akta maksimal 7 MB</p>
      </v-col>
    </v-row>
    <dialog-view-pdf
      :visible="dialogViewPdf"
      @setDialog="setDialog"
      :urlPdf="urlPdfSelected"
    />
    <dialog-terms-conditions
      :visible="dialogTermsConditions"
      @setDialog="setDialog"
    />
  </v-container>
</template>

<script>
export default {
  name: 'CompanyDocuments',
  props: {
    form: {
      type: Object,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    if (this.readOnly) this.headers.pop();
  },
  watch: {
    readOnly(newVal) {
      if (!newVal && this.headers.length === 4) {
        this.headers.push({
          text: this.$_strings.companyDocuments.ACTION_TABLE_HEADER_LABEL,
          value: 'actionColumn',
          align: 'center',
          class: 'white--text primary text-capitalize',
          cellClass: 'w-10',
          sortable: false,
        });
      }
    },
  },
  data() {
    return {
      isAgree: false,
      items: [],
      itemDelete: {},
      dialogDeleteDocument: false,
      dialogTermsConditions: false,
      dialogViewPdf: false,
      urlPdfSelected: '',
      headers: [
        {
          text: this.$_strings.companyDocuments.NO_TABLE_HEADER_LABEL,
          value: 'no',
          cellClass: 'w-10',
        },
        {
          text: this.$_strings.companyDocuments.NAME_TABLE_HEADER_LABEL,
          value: 'documentName',
        },
        {
          text: this.$_strings.companyDocuments.MANDATORY_TABLE_HEADER_LABEL,
          value: 'documentInformation.isMandatory',
          cellClass: 'w-20',
        },
        {
          text: this.$_strings.companyDocuments.DESCRIPTION_TABLE_HEADER_LABEL,
          value: 'documentInformation.descriptions',
          cellClass: 'w-20',
        },
        {
          text: this.$_strings.companyDocuments.ACTION_TABLE_HEADER_LABEL,
          value: 'actionColumn',
          align: 'center',
          class: 'white--text primary text-capitalize',
          cellClass: 'w-10',
          sortable: false,
        },
      ],
    };
  },
  computed: {
    displayedHeader() {
      return this.headers.map((i) => ({
        ...i,
        class: 'white--text primary text-capitalize',
        // cellClass: 'clickable',
      }));
    },
  },
  methods: {
    submit({ isDraft }) {
      this.$emit('submitDocument', isDraft);
    },
    readFile(ev, item) {
      // GET DESCRIPTION FOR CHECK IS AKTA ?
      const isAkta = item.documentInformation.descriptions.toLowerCase().includes('akta');

      const file = ev.target.files[0];
      const fileType = file.name.split('.').pop().toLowerCase();
      const fileSize = file.size;
      const fileName = file.name;
      // CHECK FILE TYPE ONLY JPG, JPEG, PDF, PNG
      const fileTypeSupport = ['jpg', 'jpeg', 'png', 'pdf', 'zip'];
      if (!fileTypeSupport.includes(fileType)) {
        return this.$dialog.notify.error('Format file tidak didukung');
      }
      const reader = new FileReader();
      reader.onload = (e) => {
        // 10240000 => 10MB
        // 7168000 => 7MB
        if (isAkta && fileSize > 7168000) {
          return this.$dialog.notify.error('Ukuran akta tidak boleh lebih dari 7MB.');
        }
        // 1024000 => 1MB
        if (!isAkta && fileSize > 1024000) {
          return this.$dialog.notify.error('Ukuran file tidak boleh lebih dari 1MB.');
        }
        this.setFile(e.target.result, fileSize, fileType, item, fileName);
      };
      const input = this.$refs[`file-upload${item.documentInformation.id}`];
      input.type = 'text';
      input.type = 'file';
      reader.readAsDataURL(file);
    },
    setFile(base64File, size, fileType, item, fileName) {
      this.$set(item, 'encodedFile', base64File);
      this.$set(item, 'size', size);
      this.$set(item, 'type', fileType);
      this.$set(item, 'fileName', fileName);
    },
    deleteFile(index, item) {
      this.$dialog.warning({
        text: `Anda yakin akan menghapus dokumen ${item.documentInformation.descriptions}?`,
        title: this.$_strings.common.WARNING,
        persistent: true,
        actions: {
          false: this.$_strings.common.NO,
          true: this.$_strings.common.YES,
        },
      }).then(async (res) => {
        if (!res) return;
        if (item.encodedFile) {
          this.$set(item, 'encodedFile', '');
        } else {
          this.$set(item, 'url', '');
        }
      });
    },
    setDialog(dialog) {
      this[dialog] = !this[dialog];
    },
    openDocument(item, index) {
      const fileType = item.type || (item.documentName && item.documentName.split('.').pop().toLowerCase()) || '';
      if (fileType === 'png' || fileType === 'jpg' || fileType === 'jpeg') {
        this.$refs[`viewer_image${index}`].$el.children[0].click();
        return;
      }
      if (fileType === 'pdf') {
        this.dialogViewPdf = true;
        this.urlPdfSelected = item.encodedFile || item.url;
        return;
      }
      window.open(item.url);
    },
  },
};
</script>
